.search-input-wrapper .MuiInputBase-root {
    border-radius: 30px;
}

.search-input-wrapper .MuiInputBase-root:after,
.search-input-wrapper .MuiInputBase-root:before,
.search-input-wrapper .MuiInputBase-root:hover:before,
.search-input-wrapper .MuiInputBase-root:hover {
    border: 0 !important;
}

.search-input-wrapper .MuiInputBase-root .MuiFilledInput-input {
    padding-top: 11px;
    padding-bottom: 11px;
}
